import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { IBreadCrumb } from './breadcrumb.model';
import { BREADCRUMB_TOKENIZER, BreadcrumbTokenizer } from './breadcrumb.token';

@Injectable()
export class BreadcrumbService implements OnDestroy {
  subscriptions: Subscription[] = [];
  path$!: Observable<IBreadCrumb[]>;

  constructor(@Inject(BREADCRUMB_TOKENIZER) private tokenizer: BreadcrumbTokenizer) {
    this.subscriptions.push(this.tokenizer.connect());
    this.path$ = this.tokenizer.currentPath$;
  }

  reset() {
    this.tokenizer.reset();
  }

  ngOnDestroy() {
    this.subscriptions.filter((s) => !!s && !s.closed).forEach((s) => s.unsubscribe());
  }
}
