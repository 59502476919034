import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { BreadcrumbComponent } from './breadcrumb.component';
import { BreadcrumbService } from './breadcrumb.service';

/**
 * In order to use this, you have to define a provider for the
 * BREADCRUMB_TOKENIZER injection token.
 *
 * We have predefined a couple of classes you can use:
 * - <code>UrlTokenizer</code>
 *    Creates breadcrumb items from url alone
 *    This can be used as a default
 *
 * - <code>NodeAndUrlTokenizer</code>
 *    Creates breadcrumb items from both assetstructure selection and url path
 *    This can only be used inside logic-suite as this depends on the access-api
 **/
@NgModule({
  declarations: [BreadcrumbComponent],
  imports: [CommonModule, RouterModule, TranslateModule, MatButtonModule, MatIconModule],
  providers: [BreadcrumbService],
  exports: [BreadcrumbComponent],
})
export class BreadcrumbModule {}
