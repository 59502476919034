import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { SiteService } from './site.service';

@Component({
  selector: 'bs-site-selector',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatFormFieldModule, MatSelectModule, TranslateModule],
  template: `
    <mat-form-field [title]="'Choose site' | translate">
      <mat-icon matPrefix>flag</mat-icon>
      <mat-select [value]="value" (selectionChange)="setSite($event)" panelClass="selection">
        @for (site of availableSites; track site) {
          <mat-option [value]="site">{{ site | uppercase }}</mat-option>
        }
      </mat-select>
      <ng-content></ng-content>
    </mat-form-field>
  `,
  styles: [
    `
      :host {
        display: block;
        ::ng-deep .mat-mdc-form-field.mat-mdc-form-field-type-mat-select {
          max-height: 2.5rem;
          overflow: hidden;
        }
      }
    `,
  ],
})
export class SiteSelectorComponent {
  private site = inject(SiteService);
  availableSites = this.site.availableSites;
  value = this.site.selectedSite();

  setSite(evt: MatSelectChange) {
    this.site.selectedSite$.next(evt.value);
  }
}
