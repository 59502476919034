import { InjectionToken } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { IBreadCrumb } from './breadcrumb.model';

export interface BreadcrumbTokenizer {
  currentPath$: Observable<IBreadCrumb[]>;
  connect: () => Subscription;
  reset: () => void;
}

export const BREADCRUMB_TOKENIZER = new InjectionToken<BreadcrumbTokenizer>('breadcrumb-tokenizer');
