import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IBreadCrumb } from '@logic-suite/shared/nav/breadcrumb';
import { firstValueFrom } from 'rxjs';
import { CustomerService } from '../views/customer/customer.service';
import { formatMeter } from '../views/meter/meter.pipe';
import { MeterService } from '../views/meter/meter.service';
import { SiteService } from './site-selector/site.service';

@Injectable({ providedIn: 'root' })
export class CrumbLookupService {
  destroyRef = inject(DestroyRef);
  constructor(
    private meter: MeterService,
    private customer: CustomerService,
    private site: SiteService,
  ) {}

  async lookup(crumb: IBreadCrumb, index: number, path: IBreadCrumb[], original: IBreadCrumb[]) {
    if (index == 0) {
      crumb.params = {};
      return crumb;
    }
    switch (path[0].name) {
      case 'Meter':
        if (index == 1 && !isNaN(+crumb.name)) {
          const meter = await firstValueFrom(
            this.meter.getMeter(+crumb.name, crumb?.params?.meterContract).pipe(takeUntilDestroyed(this.destroyRef)),
          );
          crumb.name = formatMeter(meter.meteringPointID, this.site.selectedSite());
        }
        break;
      case 'Customer':
        if (index == 1) {
          const customer = await firstValueFrom(
            this.customer.getCustomer(crumb.name).pipe(takeUntilDestroyed(this.destroyRef)),
          );
          crumb.name = customer.customerName;
          Object.assign(crumb.params, { customerID: customer.customerID });
        }
        if (index == 3 && original[2].name === 'Meter' && crumb.name !== 'Multiple') {
          const meter = await firstValueFrom(
            this.meter.getMeter(+crumb.name, crumb.params?.meterContract).pipe(takeUntilDestroyed(this.destroyRef)),
          );
          crumb.name = formatMeter(meter.meteringPointID, this.site.selectedSite());
        }
        break;
      case 'Corrections':
      case 'Invoice':
        if (index == 1) {
          const customer = await firstValueFrom(
            this.customer.getCustomer(crumb.name).pipe(takeUntilDestroyed(this.destroyRef)),
          );
          crumb.name = customer.customerName;
        }
        break;
    }
    return crumb;
  }
}
