<ul class="breadcrumb">
  <li *ngIf="addBackLink">
    <a
      [routerLink]="backLink()"
      [queryParams]="backParams()"
      [title]="('Back to last page' | translate) + '\n' + ('Hold down ALT key and press ⬅️ to activate' | translate)"
      [disabled]="backLink().length == 0"
      mat-mini-fab
      color="primary"
    >
      <mat-icon>keyboard_backspace</mat-icon>
    </a>
  </li>
  @for (crumb of transformedBread(); track crumb.name) {
    <li>
      @if (current() != crumb) {
        <a [routerLink]="[crumb.url]" [queryParams]="crumb.params">{{ getKey(crumb.name) | translate }}</a>
      } @else {
        <span>{{ getKey(crumb.name) | translate }}</span>
      }
    </li>
  }
</ul>
