import { Component, HostBinding, inject, Input, OnDestroy, OnInit, signal } from '@angular/core';
import { Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { IBreadCrumb } from './breadcrumb.model';
import { BreadcrumbService } from './breadcrumb.service';

/**
 * @deprecated Use the new breadcrumb component instead where possible.
 */
@Component({
  selector: 'lib-breadcrumb-old',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  private readonly breadcrumb = inject(BreadcrumbService);
  private readonly translate = inject(TranslateService);

  /**
   * Allows users to extend the breadcrumb with custom functionality.
   * With this, you can change the contents of the breadcrumb, or add
   * new content.
   *
   * It will iterate over the calculated breadcrumb, giving you the chance
   * to alter each item as you go.
   */
  @Input() translator: (
    /** The currently iterated element */
    crumb: IBreadCrumb,
    /** The iteration index */
    index: number,
    /** The accumulator array, which will end up being the source of truth for the display */
    path: IBreadCrumb[],
    /** The original array is included if you need to compare */
    original: IBreadCrumb[],
  ) => IBreadCrumb | Promise<IBreadCrumb> | null = this.defaultTranslator;
  @Input() custom = false;
  @Input() addBackLink = false;
  @Input() visibleFromLevel = 0;

  subscriptions: Subscription[] = [];
  bread: IBreadCrumb[] = [{ name: '', url: '/', params: {} }];
  transformedBread = signal<IBreadCrumb[]>([]);

  @HostBinding('class.hidden')
  shouldHide = true;

  backLink = signal<string[]>([]);
  backParams = signal<Params>({});
  current = signal<IBreadCrumb | undefined>(undefined);

  ngOnInit() {
    if (this.custom) this.breadcrumb.reset();
    this.subscriptions.push(
      this.breadcrumb.path$.subscribe(async (bread) => {
        const transformedBread = [] as IBreadCrumb[];
        this.bread = bread;
        for (let i = 0; i < this.bread.length; i++) {
          const crumb = this.bread[i];
          const transformed = await this.translator(crumb, i, transformedBread, this.bread);
          if (transformed) {
            transformedBread.push(transformed);
            this.shouldHide = transformedBread.length < this.visibleFromLevel + 1;
          }
        }

        // Calculate back link based on the second to last item in the breadcrumb
        const last = transformedBread.at(-2);
        this.backLink.set(last != null ? [last.url] : []);
        this.backParams.set(last?.params ?? {});
        this.transformedBread.set(transformedBread);
        this.current.set(transformedBread.at(-1));
      }),
    );
  }

  getKey(name: string) {
    const store = this.translate.store.translations[this.translate.currentLang] || ({} as { [key: string]: string });
    if (!(name in store)) {
      const key = `app-${name.toLowerCase()}`;
      return key in store ? key : name;
    }
    return name;
  }

  defaultTranslator(crumb: IBreadCrumb): IBreadCrumb {
    return crumb;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
