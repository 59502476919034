import { Injectable, inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SiteService } from './site.service';

@Injectable()
export class SiteInterceptor implements HttpInterceptor {
  private readonly site = inject(SiteService);

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const url = req.url?.toLowerCase() ?? '';
    if (!url.startsWith('/api')) return next.handle(req);

    req = req.clone({
      setHeaders: {
        'X-Instance': `${this.site.selectedSite()}`,
      },
    });
    return next.handle(req);
  }
}
