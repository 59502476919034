<header>
  <mat-toolbar color="primary">
    <div class="spacer"></div>
    <div class="tools">
      <bs-site-selector></bs-site-selector>
      <lib-language-selector
        (langChanged)="setLanguage($event)"
        [title]="'Choose language' | translate"
      ></lib-language-selector>
    </div>
  </mat-toolbar>
</header>
<aside>
  <a [routerLink]="['/']">
    <picture>
      <img src="assets/logo.svg" />
    </picture>
  </a>
  <mat-list>
    <!-- Render menu -->
    @for (group of menu; track group.name) {
      <h3 matSubheader>
        <span>{{ group.name | translate }}</span>
      </h3>
      @for (item of group.items; track item) {
        <a mat-list-item routerLinkActive="active" [routerLink]="[item.url]">
          <div matListItemIcon class="icon">
            <mat-icon *ngIf="item.svgIcon" [svgIcon]="item.svgIcon"></mat-icon>
            <mat-icon *ngIf="item.fontIcon">{{ item.fontIcon }}</mat-icon>
            <mat-icon class="hover" (click)="addToFavorites(item, $event)">push_pin</mat-icon>
          </div>
          <span
            matListItemLine
            [bsKeyboard]="{ ctrlKey: true, position: 'left', key: '' + item.index }"
            [handler]="keyboardRoute(item.url)"
          >{{ item.name | translate }}</span
          >
        </a>
      }
    }

    <!-- Extra menu functionality -->
    @if (!isCollapsed) {
      <h3 matSubheader>
        <span>{{ 'Recent/Pinned' | translate }}</span>
        <button mat-icon-button (click)="toggleHistory()">
          <mat-icon>{{ historyOpen ? 'expand_less' : 'expand_more' }}</mat-icon>
        </button>
      </h3>
      <section
        class="history"
        [ngClass]="{ open: historyOpen }"
        [matTooltip]="'HINT: Click icon to pin' | translate"
        matTooltipPosition="below"
      >
        @for (item of favorites(); track item) {
          <a class="historyItem" [routerLink]="item.url" [queryParams]="{ h: 1 }">
            <div class="icon" matListItemIcon>
              <mat-icon *ngIf="item.svgIcon" [svgIcon]="item.svgIcon"></mat-icon>
              <mat-icon *ngIf="item.fontIcon">{{ item.fontIcon }}</mat-icon>
              <mat-icon class="hover" (click)="removeFavorite(item, $event)">close</mat-icon>
            </div>
            @if (item.names?.length) {
              <span matListItemLine>
                @for (name of item.names; track name) {
                  <span>{{ name | translate }}</span>
                }
              </span>
            } @else {
              <span matListItemLine>{{ item.name | translate }}</span>
            }
          </a>
        }
        @if (favorites().length && history().length) {
          <div class="divider"></div>
        }
        @for (item of history(); track item) {
          <a class="historyItem" [routerLink]="item.url" [queryParams]="item.query">
            <div class="icon" matListItemIcon>
              <mat-icon *ngIf="item.svgIcon" [svgIcon]="item.svgIcon"></mat-icon>
              <mat-icon *ngIf="item.fontIcon">{{ item.fontIcon }}</mat-icon>
              <mat-icon class="hover" (click)="addToFavorites(item, $event)">push_pin</mat-icon>
            </div>
            @if (item.names?.length) {
              <span matListItemLine>
                @for (name of item.names; track name) {
                  <span>{{ name | translate }}</span>
                }
              </span>
            } @else {
              <span matListItemLine>{{ item.name | translate }}</span>
            }
          </a>
        }
      </section>
    }
  </mat-list>

  <div class="spacer"></div>
  <button mat-flat-button (click)="toggleMenu()" class="triggerContainer">
    <mat-icon class="expandCollapseTrigger">double_arrow</mat-icon>
    @if (!isCollapsed) {
      <span>{{ 'Collapse' | translate }}</span>
    }
  </button>
</aside>

<lib-loader></lib-loader>

<main>
  <lib-breadcrumb-old [translator]="crumbTranslator()" [addBackLink]="true" [visibleFromLevel]="0"></lib-breadcrumb-old>
  <router-outlet></router-outlet>
</main>

<div class="version">v{{ version }}</div>
