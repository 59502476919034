import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AUTH_TOKEN, AuthGuard, AuthModule } from '@logic-suite/shared/auth';
import { CacheModule } from '@logic-suite/shared/cache';
import { LoaderInterceptor, LoaderModule } from '@logic-suite/shared/components/loader';
import { ErrorHandlerModule } from '@logic-suite/shared/error';
import { I18nModule, I18nRootModule, START_OF_WEEK, createTranslateLoader } from '@logic-suite/shared/i18n';
import { BREADCRUMB_TOKENIZER, BreadcrumbModule, UrlTokenizer } from '@logic-suite/shared/nav/breadcrumb';
import { assignEnv, getEnv } from '@logic-suite/shared/utils';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { IconsModule } from './shared/icons/icons.module';
import { CustomPaginator } from './shared/paginator/custom-paginator';
import { KeyboardDirective } from './shared/shortcuts/keyboard.directive';
import { SiteSelectorComponent } from './shared/site-selector/site-selector.component';
import { SiteInterceptor } from './shared/site-selector/site.interceptor';

assignEnv(environment);
@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => createTranslateLoader(http, 'lbs'),
        deps: [HttpClient],
      },
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production, // getEnv('production'),
      // Register the ServiceWorker immediately.
      registrationStrategy: 'registerImmediately',
      scope: '/',
    }),
    // NgxMaskModule.forRoot(),
    AuthModule,
    CacheModule,
    LoaderModule,
    I18nRootModule,
    I18nModule,
    IconsModule,
    ErrorHandlerModule,
    LoaderModule,
    SiteSelectorComponent,
    MatSnackBarModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatToolbarModule,
    MatListModule,
    MatDialogModule,
    KeyboardDirective,
    MatTooltipModule,
    BreadcrumbModule,
    RouterModule.forRoot(
      [
        // {
        //   path: '',
        //   loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule),
        //   canActivate: [AuthGuard],
        // },
        {
          path: 'customer',
          loadChildren: () => import('./views/customer/customer.module').then(m => m.CustomerModule),
          canActivate: [AuthGuard],
        },
        {
          path: 'meter',
          loadChildren: () => import('./views/meter/meter.module').then(m => m.MeterModule),
          canActivate: [AuthGuard],
        },
        {
          path: 'tariff',
          loadChildren: () => import('./views/tariff/tariff.module').then(m => m.TariffModule),
          canActivate: [AuthGuard],
        },
        {
          path: 'invoice',
          loadChildren: () => import('./views/invoice/invoice.module').then(m => m.InvoiceModule),
          canActivate: [AuthGuard],
        },
        {
          path: 'corrections',
          loadChildren: () => import('./views/correction/correction.module').then(m => m.CorrectionModule),
          canActivate: [AuthGuard],
        },
        {
          path: 'tenant-invoice',
          loadChildren: () => import('./views/tenant-invoice/tenant-invoice.module').then(m => m.TenantInvoiceModule),
          canActivate: [AuthGuard],
        },
        {
          path: 'tenant-corrections',
          loadChildren: () =>
            import('./views/tenant-correction/tenant-correction.module').then(m => m.TenantCorrectionModule),
          canActivate: [AuthGuard],
        },
        { path: '**', redirectTo: 'customer' },
      ],
      { initialNavigation: 'enabledBlocking' },
    ),
  ],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill', floatLabel: 'always' } },
    { provide: AUTH_TOKEN, useValue: getEnv('auth') },
    { provide: START_OF_WEEK, useValue: 1 },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SiteInterceptor, multi: true },
    { provide: MatPaginatorIntl, useClass: CustomPaginator },
    { provide: BREADCRUMB_TOKENIZER, useClass: UrlTokenizer },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
