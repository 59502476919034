import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CustomPaginator extends MatPaginatorIntl {
  constructor(private translate: TranslateService) {
    super();
    this.translate.onLangChange.subscribe(lang => {
      this.itemsPerPageLabel = this.translate.instant('Items per page');
      this.changes.next();
    });
  }

  getRangeLabel = function (page: number, pageSize: number, length: number) {
    return `${page + 1} / ${length / pageSize}`;
  };
}
