import { getEnv } from '@logic-suite/shared/utils/getEnv';

export const environment = {
  production: true,
  auth: {
    clientId: 'logicClient',
    tenantId: 'Logic',
    get issuer() {
      const env = getEnv('env');
      const s = env === 'production' || env === 'prod' ? '' : `${env}.`;
      return `https://${s}auth.noova.no/realms/`;
    },
    redirectUri: '/callback',
  },
  get errorLevel() {
    return getEnv('env') === 'dev' ? 'warn' : 'none';
  },
  customLogin: true,
  showHoodColors: getEnv('env') !== 'prod',
};
